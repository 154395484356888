import { createContext, useState, useEffect } from 'react';

export const GlobalContext = createContext(null);

export const GlobalProvider = ({ children }) => {
	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const STORE_ID = process.env.REACT_APP_STORE_ID;
	const [carrinho, setCarrinho] = useState(
		JSON.parse(window.localStorage.getItem('carrinho'))
	);
	const [cartAffiliate, setCartAffiliate] = useState(
		JSON.parse(window.localStorage.getItem('cartAffiliate'))
	);
	const [carrinhoConclusaoInfo, setCarrinhoConclusaoInfo] = useState(
		JSON.parse(window.localStorage.getItem('carrinhoConclusaoInfo'))
	);
	const [salesHistory, setSalesHistory] = useState(
		JSON.parse(window.localStorage.getItem('salesHistory'))
	);
	const [bagsData, setBagsData] = useState([]);
	const [affiliateId, setAffiliateId] = useState();

	// LOAD CARRINHO FUNCTION
	const loadVinculacao = () => {
		setAffiliateId(cartAffiliate);

		setBagsData(
			carrinho?.map((bag) => ({
				studentId: `${bag.studentId}`,
				cartItems: bag.cartItems.map((cartItem) => ({
					itemTOTVSId: `${cartItem.itemTOTVSId}`,
					quantity: Number(cartItem.quantity),
				})),
			}))
		);
	};

	// CALL LOADVINCULACAO WHENEVER CARRINHO CHANGES && BAGSDATA IS SET ON SESSION STORAGE
	useEffect(() => {
		loadVinculacao();
	}, [carrinho]); // eslint-disable-line react-hooks/exhaustive-deps

	const value = {
		carrinho,
		setCarrinho,
		cartAffiliate,
		setCartAffiliate,
		bagsData,
		setBagsData,
		affiliateId,
		setAffiliateId,
		loadVinculacao,
		BASE_URL,
		STORE_ID,
		carrinhoConclusaoInfo,
		setCarrinhoConclusaoInfo,
		salesHistory,
		setSalesHistory,
	};
	return (
		<GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
	);
};
