import React, { useContext } from 'react';
import './CartItem.styles.scss';
import { Link } from 'react-router-dom';
import IconChevronUp from '../../../assets/Icons/icon-chevron.svg';
import IconChevronDown from '../../../assets/Icons/icon-chevron-down.svg';
import BtnRemove from '../../Buttons/BtnRemove/BtnRemove';
import { useMediaQuery } from 'react-responsive';
import { CategoryContext } from '../../../contexts/CategoryContext';
import { CartContext } from '../../../contexts/CartContext';
import { unidadeRetirada } from '../../../utils/affiliatesInfo';
import { percentage } from '../../../utils/percentage';
import { calculateInstallments } from '../../../utils/calculateInstallments';

const CartItem = ({ cartItem, bag }) => {
	const { name } = bag;
	let nomeEstudante = !name ? 'Selecionar estudante' : name.toLowerCase();
	const { NOME, PRECO, DESCONTO, TIPO, CODCOLIGADA, quantity, productImage, productSlug, category, paymentPlan, PARCELAS } = cartItem;
	const { categoryIcons } = useContext(CategoryContext);
	const { removerVinculacao, montarCarrinho } =
		useContext(CartContext);

	const discountAmount = PRECO - DESCONTO;

	const percentageDiscount = percentage(discountAmount, PRECO);

	const title = NOME.toLowerCase();

	const image = productImage;

	const cartItemCategory = category.title;

	const isMobileDevice = useMediaQuery({
		query: '(min-device-width: 480px)',
	});

	const Student = bag;

	const Product = { id: cartItem.itemTOTVSId, affiliate: { id: cartItem.affiliateId } }

	let Quantity = quantity;

	function increment() {
		Quantity += 1;
		return Quantity;
	}

	function decrement() {
		if (Quantity > 1) {
			return (Quantity -= 1);
		} else {
			return (Quantity = 1);
		}
	}

	function handleIncrement() {
		increment();
		montarCarrinho(true, Student, Product, Quantity);
	}

	function handleDecrement() {
		decrement();
		montarCarrinho(false, Student, Product, Quantity);
	}

	function handleRemoverItem() {
		removerVinculacao(false, Student, Product);
	}

	const slug = productSlug;

	return (
		<>
			<div className="cartItem-container">
				<div className="cartItem-quantity">
					<div className="cartItem-quantity-container">
						<div id="decrement-count">
							<input
								type="image"
								id={TIPO === 'S' || TIPO === 'M' ? 'disabled-arrow' : 'up-arrow'}
								disabled={TIPO === 'S' || TIPO === 'M' ? true : false}
								src={IconChevronUp}
								alt="minus"
								onClick={() => handleIncrement()}
							/>
						</div>
						<div id="total-count">
							<p>{quantity}</p>
						</div>
						<div id="increment-count">
							<input
								type="image"
								id={Quantity === 1 ? `disabled-arrow` : `down-arrow`}
								disabled={Quantity === 1 ? true : false}
								src={IconChevronDown}
								alt="plus"
								onClick={() => handleDecrement()}
							/>
						</div>
					</div>
				</div>

				<div className="cartItem-img-container">
					<img src={image} alt={`${NOME}`} />
				</div>

				<div className="cartItem-info-container">
					<div className="cartItem-info-top">
						<Link to={`/produto/${category.slug}/${slug}`}>
							<div className="cartItem-name">
								{title.length <= 35 ? (TIPO === 'S' ? `${title}` : title) :
									(TIPO === 'S' ? title.substr(0, 35) + `\u2026` : title.substr(0, 35) + '\u2026')}
							</div>
						</Link>
						{(TIPO === 'S' || TIPO === 'M') && <div className="unit-name">Unidade - {unidadeRetirada(CODCOLIGADA)}</div>}
					</div>
					<div className="cartItem-info-mid">
						{isMobileDevice && (
							<div className="cartItem-categoria">
								<span className="iconCateg material-symbols-outlined">
									{categoryIcons[category.slug] ??
										categoryIcons['outros']}
								</span>
								{cartItemCategory}
								{/* <span className="categ-txt">{cartItemCategory}</span> */}
							</div>
						)}
						<div className="cartItem-estudante">
							<span className="iconVinc material-symbols-outlined">link</span>
							{nomeEstudante?.length <= 25
								? nomeEstudante
								: nomeEstudante?.substr(0, 25) + '\u2026'}
						</div>
					</div>
					<div className="cartItem-info-bot">
						{PRECO === 0 ? (
							<div className="cartItem-price">
								<h4>
									<span className="price-green">
										R$ 0,00{' '}
									</span>
									produto/serviço grátis
								</h4>
							</div>
						) : (
							<div className="cartItem-price">
								<h4>
									<span className="price-green">
										R$ {`${calculateInstallments(paymentPlan, TIPO, PARCELAS)}x${((DESCONTO * Quantity) / calculateInstallments(paymentPlan, TIPO, PARCELAS))?.toFixed(2)}`}
									</span>{' '}
									{/* à vista */}
									{percentageDiscount < 1 ? null : (
										<span className="porcentagem-desconto">
											({percentageDiscount}% desconto)
										</span>
									)}
								</h4>
								<h3>
									de {/* ou{' '} */}
									<span className="price-blue">
										R$ {`${calculateInstallments(paymentPlan, TIPO, PARCELAS)}x${((PRECO * Quantity) / calculateInstallments(paymentPlan, TIPO, PARCELAS))?.toFixed(2)}`}
									</span>{' '}
									{/* parcelado */}
								</h3>
							</div>
						)}
						<BtnRemove OnClick={() => handleRemoverItem()} />
					</div>
				</div>
			</div>
		</>
	);
};

export default CartItem;
