import { createContext, useState, useEffect, useContext } from 'react';
import { AccountContext } from './AccountContext';
import { GlobalContext } from './GlobalContext';
import { ModalContext } from './ModalContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const CartContext = createContext({
	isCartOpen: false,
	cartCount: 0,
	cartTotal: 0,
	loading: false,
});

export const CartProvider = ({ children }) => {
	const [isCartOpen, setIsCartOpen] = useState(false);
	const navigate = useNavigate();
	const [cartCount, setCartCount] = useState(0);
	const [cartTotal, setCartTotal] = useState(0);
	const [cartTotalParcelado, setCartTotalParcelado] = useState(0);
	const [loading, setLoading] = useState(false);
	const [selectedStudent, setSelectedStudent] = useState();

	const { setIsOpen } = useContext(ModalContext);
	const { customerId, userToken, Toast, isLoggedIn, tokenExpireLogout } =
		useContext(AccountContext);
	const {
		carrinho,
		setCarrinho,
		bagsData,
		setBagsData,
		setAffiliateId,
		BASE_URL,
		STORE_ID,
	} = useContext(GlobalContext);

	// CART COUNT
	const flatCarrinho = carrinho?.flatMap((bag) => bag.cartItems);
	useEffect(() => {
		if (isLoggedIn !== true) {
			return;
		} else {
			const newCartCount = flatCarrinho.reduce(
				(total, cartItem) => total + cartItem.quantity,
				0
			);
			setCartCount(newCartCount);
		}
	}, [flatCarrinho]); // eslint-disable-line react-hooks/exhaustive-deps

	// CART TOTAL
	useEffect(() => {
		if (isLoggedIn !== true) {
			return;
		} else {
			const newCartTotal = flatCarrinho?.reduce(
				(total, cartItem) => total + cartItem.quantity * cartItem.DESCONTO,
				0
			);
			const newCartTotalParcelado = flatCarrinho?.reduce(
				(total, cartItem) => total + cartItem.quantity * cartItem.DESCONTO,
				0
			);
			setCartTotal(newCartTotal.toFixed(2));
			setCartTotalParcelado(newCartTotalParcelado.toFixed(2));
		}
	}, [flatCarrinho]); // eslint-disable-line react-hooks/exhaustive-deps

	// TOGGLE CART
	function toggleCart() {
		setIsCartOpen(!isCartOpen);
	}

	// FUNÇÃO DE ENVIAR O CARRINHO MONTADO POR montarCarrinho OU montarCarrinhoCompraRapida PRA API
	function sendCartToAPI(isAdding, newBagsData, affiliateId, oldBags) {
		//console.log("newBagsData:", newBagsData)
		const optionsMontarCarrinho = {
			method: 'POST',
			url: `${BASE_URL}/carts/client-add-item-to-cart`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
			data: {
				affiliateId: `${affiliateId}`,
				storeId: `${STORE_ID}`,
				customerId: `${customerId}`,
				bags: newBagsData,
			},
			validateStatus: () => true,
		};

		setLoading(true);

		axios
			.request(optionsMontarCarrinho)
			.then(function (response) {
				if (response.status === 200) {
					const responseBags = response.data.cart.bags.map((bag) => ({
						cartId: response.data.cart.cartId,
						name: bag.name,
						studentId: bag.studentId,
						cartItems: bag.cartItems.map((cartItem) => ({
							itemTOTVSId: cartItem.item.itemTOTVSId,
							quantity: cartItem.quantity,
							PRECO: cartItem.item.PRECO,
							DESCONTO: cartItem.item.DESCONTO,
							TIPO: cartItem.item.TIPO,
							NOME: cartItem.item.NOME,
							PARCELAS: cartItem.item.PARCELAS,
							TURNO: cartItem.item.TURNO,
							CODCOLIGADA: cartItem.item.CODCOLIGADA,
							cartItemid: cartItem.cartItemid,
							paymentPlan: cartItem.paymentPlan,
							productId: cartItem.product.productId,
							productName: cartItem.product.title,
							productSlug: cartItem.product.slug,
							productImage: cartItem.product.image,
							category: cartItem.product.category,
							affiliateId: affiliateId
						})),
					}));

					isAdding === true
						? Toast.fire({
							icon: 'success',
							iconColor: '#fff',
							color: '#fff',
							background: '#55d98d',
							title: 'Item adicionado ao carrinho!',
						})
						: Toast.fire({
							icon: 'error',
							iconColor: '#fff',
							color: '#fff',
							background: '#ff6363',
							title: 'Item removido do carrinho.',
						});

					if (response.data.cart.itemsRemoved.length > 0)
						Toast.fire({
							icon: 'warning',
							iconColor: '#fff',
							color: '#fff',
							background: '#ffff00',
							title: 'Alguns itens foram removidos do carrinho pelo gestor.',
						});

					setCarrinho(responseBags);
					localStorage.setItem('carrinho', JSON.stringify(responseBags));
				} else if (response.status === 401) {
					tokenExpireLogout(response);
					const newBags = bagsData.filter((bag) => bag.studentId !== selectedStudent.studentId);
					setBagsData(newBags);
				} else if (response.status === 402) {
					toggleCart()
					Toast.fire({
						icon: 'error',
						iconColor: '#fff',
						color: '#fff',
						background: '#ff6363',
						title: `${response.data.error.message}`,
					});
					setCarrinho([]);
					localStorage.setItem('carrinho', '[]');
					setTimeout(() => {
						navigate("/")
						navigate(0)
					}, 2500)
				} else {
					Toast.fire({
						icon: 'error',
						iconColor: '#fff',
						color: '#fff',
						background: '#ff6363',
						title: `${response.data.error.message}`,
					});
					//console.log("Selected student id,", selectedStudent.id)
					setBagsData(JSON.parse(oldBags));
				}

				setLoading(false);
			})
			.catch(function (error) {
				console.error(error);
				setLoading(false);
			});
		optionsMontarCarrinho.data.bags = carrinho;
	}

	// REMOVER ITEM DO CARRINHO
	const removerVinculacao = (isAdding, Student, Product) => {
		const studentId = Student?.studentId;
		const itemTOTVSId = Product?.id;
		const affiliateId = Product?.affiliate.id;
		setAffiliateId(Product?.affiliate.id);
		setSelectedStudent(Student);

		let newBagsData = bagsData;
		const oldBags = JSON.stringify(bagsData);
		// CHECK IF BAG EXISTS
		{
			// if bag does exist...
			const existingBag = newBagsData.find(
				(bag) => bag.studentId === studentId
			);
			if (existingBag) {
				const existingCartItem = existingBag.cartItems.find(
					(item) => item.itemTOTVSId === itemTOTVSId
				);
				if (existingBag.cartItems.length === 1) {
					const index = newBagsData.indexOf(existingBag);
					newBagsData.splice(index, 1);
				}
				// and cart item already exists within the bag, increment quantity
				if (existingCartItem) {
					const index = existingBag.cartItems.indexOf(existingCartItem);
					existingBag.cartItems.splice(index, 1);
				}
			}
		}
		sendCartToAPI(isAdding, newBagsData, affiliateId, oldBags);
		setSelectedStudent();
	};

	// FUNÇÃO DE MONTAR O CARRINHO ANTES DE ENVIAR PRA API NO ADD/INCREMENT/DECREMENT/REMOVE DO CART
	function montarCarrinho(isAdding, Student, Product, Quantity) {
		const studentId = Student?.studentId;
		const itemTOTVSId = Product?.id;
		const affiliateId = Product?.affiliate.id;
		setAffiliateId(Product?.affiliate.id);
		setSelectedStudent(Student);
		const oldBags = JSON.stringify(bagsData);
		// CHECK IF BAG EXISTS
		// if bag is empty, create first one
		let newBagsData = bagsData;
		if (newBagsData.length === 0) {
			setBagsData((prevState) => [
				...prevState,
				{
					studentId: `${studentId}`,
					cartItems: [
						{
							itemTOTVSId: `${itemTOTVSId}`,
							quantity: 1,
						},
					],
				},
			]);
			newBagsData.push({
				studentId: `${studentId}`,
				cartItems: [
					{
						itemTOTVSId: `${itemTOTVSId}`,
						quantity: 1,
					},
				],
			});
		} else {
			// if bag does exist...
			const existingBag = newBagsData.find(
				(bag) => bag.studentId === studentId
			);
			let alreadyExist;
			if (existingBag)
				alreadyExist = existingBag.cartItems.filter((cartItem) => cartItem.itemTOTVSId === Product.id).length;
			if (existingBag && Product.TIPO === "S") {
				return Toast.fire({
					icon: 'error',
					iconColor: '#fff',
					color: '#fff',
					background: '#ff6363',
					title: 'Este aluno já possui um curso ou evento a ser comprado no carrinho.',
				});
			} else if (existingBag && Product.TIPO === "M" && alreadyExist) {
				return Toast.fire({
					icon: 'error',
					iconColor: '#fff',
					color: '#fff',
					background: '#ff6363',
					title: 'Este aluno já possui um produto manual a ser comprado no carrinho.',
				});
			} else if (existingBag) {
				const existingCartItem = existingBag.cartItems.find(
					(item) => item.itemTOTVSId === itemTOTVSId
				);
				// and cart item already exists within the bag, increment quantity
				if (existingCartItem) {
					if (Quantity !== undefined) {
						existingCartItem.quantity = Number(Quantity);
					} else {
						existingCartItem.quantity++;
					}
					// otherwise, returning cart item with quantity 1
				} else {
					existingBag.cartItems.push({
						itemTOTVSId: `${itemTOTVSId}`,
						quantity: 1,
					});
				}
			} else {
				setBagsData((prevState) => [
					...prevState,
					{
						studentId: `${studentId}`,
						cartItems: [
							{
								itemTOTVSId: `${itemTOTVSId}`,
								quantity: 1,
							},
						],
					},
				]);
				newBagsData.push({
					studentId: `${studentId}`,
					cartItems: [
						{
							itemTOTVSId: `${itemTOTVSId}`,
							quantity: 1,
						},
					],
				});
			}
		}
		sendCartToAPI(isAdding, newBagsData, affiliateId, oldBags);
	}

	// FUNÇÃO MONTAR CARRINHO COMPRA-RAPIDA
	function montarCarrinhoCompraRapida(
		isAdding,
		Student,
		ProductList,
		Quantity
	) {
		const studentId = Student?.studentId;
		const affiliateId = ProductList[0].affiliate?.id;
		setAffiliateId(ProductList[0].affiliate?.id);
		setSelectedStudent(Student);
		const oldBags = JSON.stringify(bagsData);
		// CHECK IF BAG EXISTS
		// if bag is empty, create first one
		let newBagsData = bagsData;
		if (newBagsData.length === 0) {
			//console.log('bag doesnt exist', newBagsData);
			setBagsData((prevState) => [
				...prevState,
				{
					studentId: `${studentId}`,
					cartItems: ProductList.map((cartItem) => ({
						itemTOTVSId: `${cartItem.id}`,
						quantity: 1,
					})),
				},
			]);
			newBagsData.push({
				studentId: `${studentId}`,
				cartItems: ProductList.map((cartItem) => ({
					itemTOTVSId: `${cartItem.id}`,
					quantity: 1,
				})),
			});
		} else {
			//console.log('bag exists', newBagsData);
			// if bag does exist...
			const existingBag = newBagsData.find(
				(bag) => bag.studentId === studentId
			);
			if (existingBag) {
				//console.log('student found, adding items to student bag', existingBag);
				ProductList.forEach((product) => {
					const existingCartItem = existingBag.cartItems.find(
						(item) => item.itemTOTVSId === product.id
					);
					// and cart item already exists within the bag, increment quantity
					if (existingCartItem) {
						if (Quantity !== undefined) {
							existingCartItem.quantity = Number(Quantity);
						} else {
							//console.log('item found, incrementing quantity');
							existingCartItem.quantity++;
						}
						// otherwise, returning cart item with quantity 1
					} else {
						//console.log('item not found, adding first item to bag');
						existingBag.cartItems.push({
							itemTOTVSId: `${product.id}`,
							quantity: 1,
						});
					}
				});
			} else {
				//console.log('student not found, creating new student bag');
				setBagsData((prevState) => [
					...prevState,
					{
						studentId: `${studentId}`,
						cartItems: ProductList.map((cartItem) => ({
							itemTOTVSId: `${cartItem.id}`,
							quantity: 1,
						})),
					},
				]);
				newBagsData.push({
					studentId: `${studentId}`,
					cartItems: ProductList.map((cartItem) => ({
						itemTOTVSId: `${cartItem.id}`,
						quantity: 1,
					})),
				});
			}
		}
		//console.log(newBagsData);
		sendCartToAPI(isAdding, newBagsData, affiliateId, oldBags);
	}

	// handler add item to cart
	const addItemToCartHandler = (Student, Product, Quantity) => {
		montarCarrinho(true, Student, Product, Quantity);
		setSelectedStudent();
		setIsOpen(false);
		setIsCartOpen(true);
	};

	// handler add item to cart
	const addItemsToCartHandler = (Student, ProductList, Quantity) => {
		var promise = new Promise((resolve, reject) => {
			let test = 'true';
			if (test === 'true') {
				resolve(
					montarCarrinhoCompraRapida(true, Student, ProductList, Quantity)
				);
			} else {
				reject(Error('Promise rejected'));
			}
		});

		promise.then(function (result) {
			console.log(result);
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		});
	};

	const value = {
		cartCount,
		cartTotal,
		loading,
		selectedStudent,
		isCartOpen,
		setIsCartOpen,
		toggleCart,
		setSelectedStudent,
		removerVinculacao,
		montarCarrinho,
		addItemToCartHandler,
		addItemsToCartHandler,

		cartTotalParcelado,
	};
	return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
