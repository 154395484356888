import React, { useContext, useState } from 'react';
import CartIcon from '../Cart/CartIcon/CartIcon';
import CartDropdown from '../Cart/CartDropdown/CartDropdown';
import { Link, useNavigate } from 'react-router-dom';
import './NavbarMobile.styles.scss';

import { CartContext } from '../../contexts/CartContext';

import LogoSmall from '../../assets/Logo/logo-text.svg';
import { AccountContext } from '../../contexts/AccountContext';
import Search from './Search';
import { ProductsContext } from '../../contexts/ProductsContext';

const TopNav = () => {
	const { isCartOpen } = useContext(CartContext);
	const [open, setOpen] = useState(false);
	const { isNavExpanded } = useContext(ProductsContext);
	const { isLoggedIn, toggleAccountModal, loggedUserData, logoutHandler } =
		useContext(AccountContext);

	const name = loggedUserData?.customer.name;
	const email = loggedUserData?.customer.email;
	const firstName = name?.split(' ')[0];

	const navigate = useNavigate();
	const navigateConta = () => {
		navigate('/conta');
	};

	const accountModalHandler = () => {
		if (isLoggedIn === true) {
			navigateConta();
		} else {
			toggleAccountModal();
		}
	};

	const handleOpen = () => {
		setOpen(!open);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<nav className="header-mobile">
				<div className="logo-container">
					<Link to="/">
						<img src={LogoSmall} alt="" className="logo" />
					</Link>
				</div>
				<div className="cart-container">
					<CartIcon />
				</div>
				<div className="account-container">
					{isLoggedIn === false && (
						<button
							className="account-logged-out"
							onClick={accountModalHandler}>
							<span className="material-symbols-outlined">person</span>
						</button>
					)}
					{isLoggedIn === true && (
						<button className="account-btn" onClick={handleOpen}>
							{loggedUserData?.customer?.avatar !== '' ? (
								<img src={loggedUserData?.customer?.avatar} alt="" />
							) : (
								<span className="material-symbols-outlined">person</span>
							)}
						</button>
					)}
					{isLoggedIn === true && open === true && (
						<div className="account-dropdown">
							<ul className="account-menu">
								<li className="conta">Olá, {firstName}</li>
								<li className="email">{email}</li>
								<hr className="account-menu-hr" />
								<Link to={'/conta'} onClick={handleClose}>
									<li className="account-menu-item">
										<span
											id="minha-conta-icon"
											className="material-symbols-outlined">
											person
										</span>
										Minha Conta
									</li>
								</Link>
								<Link to={'/compra-rapida'} onClick={handleClose}>
									<li className="account-menu-item">
										<span
											id="compra-rapida-icon"
											className="material-symbols-outlined">
											shopping_cart_checkout
										</span>
										Compra Rápida
									</li>
								</Link>
								<Link to={'/atendimento'} onClick={handleClose}>
									<li className="account-menu-item">
										<span
											id="atendimento-icon"
											className="material-symbols-outlined">
											support_agent
										</span>
										Atendimento
									</li>
								</Link>
								<hr className="account-menu-hr" />
								<li
									className="account-menu-item sair"
									onClick={() => logoutHandler()}>
									<span id="sair-icon" className="material-symbols-outlined">
										logout
									</span>
									Sair
								</li>
							</ul>
						</div>
					)}
				</div>

				{isCartOpen && <CartDropdown />}
			</nav>

			<div
				className={`navmenu-mobile ${
					isNavExpanded ? 'expand-navmenu-mobile' : ''
				}`}>
				<Search />
			</div>
		</>
	);
};

export default TopNav;
